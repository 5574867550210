@import "../../../../scss//index.scss";

.edit-user-form {
  .upload-avatar {
    display: table;
    margin: 0 auto;
    border: 2px solid #9a9a9a;
    border-style: dashed;
    border-radius: 100px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .form-edit {
    //text-align: center;

    .ant-form-item {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    i {
      color: rgba(0, 0, 0, 0.25);
    }

    .ant-select {
      width: 100%;
    }

    .btn-submit {
      width: 100%;
    }
  }
}

.ant-divider-inner-text {
  color: $font-grey;
}

// .ant-form-item-label > label {
//   font-size: x-small;
//   height: 18px;
//   color: $font-grey;
// }

.biglabel .ant-form-item-label > label {
  height: 34px !important;
}
