@import "../scss/index.scss";

.home {
  min-height: calc(100vh - 70px);
  //background-image: url("../assets/img/jpg/fondo.jpg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  background-attachment: fixed;
  background-color: white;

  &__header {
    background-color: $primary-color;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    height: 90px;

    &__img {
      height: 90px;
    }
  }
  &__content {
    padding: 2% 2%;
    &__steps {
      padding: 2% 5%;
      background-color: rgba(255, 255, 255, 0.85);
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
    }
  }
}

.ant-page-header-heading {
  justify-content: center;
}

.left .ant-page-header-heading {
  justify-content: left !important;
}

.orange {
  background-color: rgba(240, 159, 48, 1);
  color: white !important;
  border-radius: 5px;
  padding: 0px 24px !important;
  margin-top: 30px;
  margin-bottom: 20px;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px;
}

.ant-steps-item-title {
  font-size: 13px;
}
