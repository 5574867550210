@import "../scss/index.scss";

.layoutbasic {
  //   min-height: 100vh;
  &__footer {
    background-color: rgba(25, 40, 73, 1);
    color: white;
    font-weight: bold;
    text-align: center;
  }
}
