@import "../../../scss/index.scss";

.personal {
  min-height: calc(100vh - 70px);

  &__header {
    background-color: rgb(173, 213, 44);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    height: 90px;
  }
  &__content {
    padding: 2% 2%;
    &__steps {
      padding: 5%;
      background-color: rgba(255, 255, 255, 0.85);
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
    }
  }
}

.header-left .ant-page-header-heading {
  justify-content: space-between !important;
}
