@import "../../../../scss/index.scss";

.admin-sider {
  overflow: auto;
  height: calc(100vh - 65px);
  position: fixed;
  left: 0;
  top: 65px;
  //background: #fff;
}

.numberCircle {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 4px 1px 1px 1px;

  background: #fff;
  border: 1px solid #666;
  color: #666;
  text-align: center;

  display: inline-block;

  margin-right: 5px;

  font: 14px Arial, sans-serif;
  font-weight: bold;
}
