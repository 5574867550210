.list-users {
  &__switch {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    > button {
      margin-right: 20px;
    }
  }
  .users-active {
    background-color: #fff;
    padding: 10px 20px;

    .ant-list-item-meta {
      display: flex;
      align-items: center;
    }
  }
  .download {
    padding: 0px 10px !important;
    font-size: 14px;
  }
}
