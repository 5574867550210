@import "../scss/index.scss";

.layout-user {
  transition: margin-left 0.2s;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0;
    height: 65px;
    background: $background-grey-dark;
  }
  &__content {
    min-height: calc(100vh - 92px);
    padding: 60px 25px 25px 25px;
  }
  &__footer {
    background-color: rgb(173, 213, 44);
    color: rgb(30, 40, 82);
    font-weight: bold;
    text-align: center;
  }
}
